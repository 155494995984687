import { Grid, Link, List, ListItem, Paper, Typography } from "@mui/material";
import { GET_ORGANIZATION_PUBLIC } from "../../Organizations/organization.queries";
import LoadingError from "../../Layout/LoadingError";
import Loading from "../../Layout/Loading";

import logoSquarePink from "../../logo-d-white-on-pink-square-100x100.png";
import { useQuery } from "@apollo/client";
import CaseStudyQuestionComponent, { CaseStudyQuestionComponentProps } from "../CaseStudyQuestionComponent";
import { Link as RouterLink } from "react-router-dom";
import { displayNewLineStyles } from "../../shared/displayNewLine.styles";
import { sayulitaAnimalsLearnings, sayulitaAnimalsQuestionData, sayulitaAnimalsTakeaways } from "../SayulitAnimalsCaseStudy/sayulita-animals-case-study-data";

/**
 *
 */
export default function SayulitAnimalsCaseStudy() {
  const organizationUuid = 'b408d8d1-aece-4a86-b9c0-20767a2678ca';

  const { loading, error, data } = useQuery(GET_ORGANIZATION_PUBLIC, { variables: { uuid: organizationUuid } });

  if (loading) return <Loading />;
  if (error || !organizationUuid) return <LoadingError />;

  const organization = data.organization;

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Typography color="primary" variant="h4" component="h1">From Hurdles to Help: SayulitAnimals' Journey with Donatic to Better Serve the Community</Typography>
      </Grid>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2, paddingBottom: 1, mt: 1 }}>
            <Typography paragraph>
              <b>Joined</b> Donatic: <b>January 2024</b><br />
              <b>2 Campaigns</b> Launched<br />
              <b>7 Donations Received</b> via Donatic<br />
              <b>56 Items Donated</b> via Donatic
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8} textAlign={{ xs: 'center', md: 'right' } }>
          <img src={organization?.logoUrl ?? logoSquarePink} height="60" /><br />
          <Link color="secondary" href={organization?.website} target="_blank">{organization?.website}</Link>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph>
          In its early years, SayulitAnimals faced significant challenges—limited funding, a shortage of volunteers, and the need for educational resources to shift the community's perspective on animals as valuable beings rather than disposable nuisances. Despite these obstacles, the organization began to make a difference. In its first year, SayulitAnimals sterilized 360 animals, found loving homes for over 40 animals, and placed nearly 30 animals in foster care. There was also a noticeable improvement in the health of street dogs and cats, and the community's awareness of the organization grew. Thirteen years later, SayulitAnimals' programs have expanded significantly, continuing to contribute to the well-being of the community. Today, SayulitAnimals stands as a powerful force in animal welfare, driven by compassion and the mission of connecting the hearts of animals and people worldwide.
        </Typography>

        <Typography paragraph>
          tldr; Key Learnings:
        </Typography>

        <Paper sx={{ padding: 5, pt: 0, pb: 0, mb: 2 }}>
          <List sx={{ listStyleType: 'disc' }}>
            {
              sayulitaAnimalsLearnings.map((learning: string, index: number) => {
                return <ListItem key={index} sx={{ display: 'list-item' }}>{learning}</ListItem>
              })
            }
          </List>
        </Paper>
      </Grid>

      <Grid item xs={12}>
      {
        sayulitaAnimalsTakeaways.map((takeaway: { title: string, content: string }, index: number) => {
          return (
            <Grid key={index} container sx={{ mt: 1, mb: 1 }}>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography color="primary" variant="h5" component="h2">
                { takeaway.title }
              </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography paragraph sx={displayNewLineStyles}>
                  { takeaway.content }
                </Typography>
              </Grid>
            </Grid>
          )
        })
      }
      </Grid>

      <Grid item xs={12}>
        <Typography color="primary" variant="h5" component="h2" sx={{ mb: 2 }}>
          Overall Impact
        </Typography>
        <Typography paragraph>
          Donatic has helped SayulitAnimals streamline their donation process, improve donor trust through transparency, and ensure they have the resources needed to continue their mission. It has reduced their logistical challenges, saved valuable time, and strengthened donor engagement.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography paragraph>
          Read on below for the full interview and to watch the videos to hear from Sara directly!
        </Typography>
       
      </Grid>

      {
        sayulitaAnimalsQuestionData.map((questionData: unknown, index: number) => {
          const data = questionData as CaseStudyQuestionComponentProps;
          console.log(data);
          return <CaseStudyQuestionComponent key={index} {...data} />
        })
      }

      <Grid item xs={12}>
        <Typography paragraph>
          Check out their Donatic page to see what they currently are campaigning for! <Link color="secondary" component={RouterLink} to={`/o/${organization?.uuid}`}>Donate now.</Link>
        </Typography>
      </Grid>
    </Grid>
);
}
