import { CaseStudyQuestionComponentProps } from "../CaseStudyQuestionComponent";

export const entreAmgiosLearnings: string[] = [
  'Donatic Enhances Accessibility & Convenience for Donors',
  'Increased Transparency & Engagement',
  'Efficiency & Reduced Administrative Work for Nonprofits',
  'Timing & Campaign Focus Matter'
];

export const entreAmigosTakeaways: { title: string, content: string }[] = [
  {
    title: "Breaking Down Barriers to Giving",
    content: `One of the biggest hurdles for EntreAmigos was enabling supporters outside of San Pancho to contribute effectively. In the past, donors would bring school supplies in suitcases when visiting or send financial donations that staff would later convert into supplies. This process was inefficient and limited the reach of their campaigns.
    
    With Donatic, supporters can now purchase needed items directly online, just like shopping on Amazon. Supplies are shipped straight to EntreAmigos, eliminating the logistical challenges of in-person donations. This ease of giving has opened up new opportunities for donors who want to support the cause but aren’t physically present.`
  },
  {
    title: "Increasing Transparency & Strengthening Donor Trust",
    content: `Previously, donors who contributed money to buy supplies had little visibility into exactly how their funds were used. While the organization was transparent in its spending, the emotional connection between donors and their contributions wasn’t as strong.
    
    By using Donatic, EntreAmigos now provides donors with a direct and tangible link to their impact. Donors select specific items to purchase, ensuring that what they give is exactly what the nonprofit receives. This level of transparency helps build trust and engagement, making donors feel more connected to the cause.`
  },
  {
    title: "Saving Time & Effort for the Nonprofit",
    content: `Running a nonprofit means balancing fundraising with on-the-ground impact. Traditional in-kind donations required EntreAmigos to coordinate logistics, pick up items, and manage storage. With Donatic, the process is streamlined—donations arrive ready to distribute, significantly reducing administrative work and allowing staff to focus on serving the community.`
  },
  {
    title: "Strategic Timing & Focused Campaigns Drive Success",
    content: `One of the key insights EntreAmigos shared was the importance of timing and specificity in fundraising. General donation requests can be helpful, but targeted campaigns with clear deadlines and goals are far more effective.
    
    For example, their school supply campaign used to be an open-ended request for funds. Now, by running a Donatic-powered campaign in the summer, they can ensure supplies are stocked before the school year begins in August. Additionally, keeping campaigns focused on one need at a time (e.g., school supplies rather than mixing multiple needs) has resulted in better donor engagement and response rates.`
  }
]

export const entreAmgiosQuestionData: CaseStudyQuestionComponentProps[] = [
  {
    question: "Can you tell us a little about EntreAmigos and its mission?",
    videoId: "Y_974PMbaHE",
    transcript: `Well, EntreAmigos started with a kitchen table under a mango tree, and after almost 20 years, it has become this amazing team that works to create possibilities for the community of San Pancho through education, environmental care, and fostering a sustainable community.`
  },
  {
    question: "What inspired you to start working with EntreAmigos?",
    videoId: "A4RRBP0-gBc",
    transcript: `Aura:
    The first thing that captivated me was this library and how I could see creativity taking place in every part of the space. That gave me the certainty that here, respect, empathy, and reflection are values that are practiced. And these values, I think, are very important for shaping our reality.
    
    That's why I wanted to be here—collaborating with the team.
    
    Sarah:
    Yeah, I came to live here with a six-month-old and was looking for the type of work I've always done—I've always worked with kids. I'm a social worker, so I was looking to work with kids, to be around education, and all of that.
    
    I walked into EntreAmigos and was just in awe of everything they had accomplished—their library, their workshops. It's such a gem to have in this town, for all of the kids to be a part of… and adults too! There's a lot for adults as well.
    
    I think for me, it was just about the ability to connect with like-minded individuals and work towards improving educational access and opportunities for everyone here.`
  },
  {
    question: "What are the biggest challenges your organization faces in achieving its goals?",
    videoId: "HU_pY9fYu2o",
    transcript: `I think there are many, but the most important one I see at this moment is to open a horizontal dialogue with the community.
    
    It's a very diverse community, so it's important to integrate all the needs and interests of everyone so they can feel seen and feel welcome here.
    
    And to strengthen the bonds between all the different parts that make us a big community.`
  },
  {
    question: "How has Donatic helped EntreAmigos achieve its mission?",
    videoId: "pdTCexVybxY",
    transcript: `So, Donatic has been a huge help for us because every year, we have a school supply giveaway where we distribute packages of school supplies and backpacks to over 250 students in town. This allows them to start their school year off great as part of our mission to provide access to education.
    
    Donatic has been a huge support because now, people who aren't necessarily in town or able to bring a suitcase full of school supplies can still support that mission. They can just click a button and say, "Hey, I'm going to donate 50 backpacks to EntreAmigos," and they come right to our door. We put them in storage, and they go straight to the kids.`
  },
  {
    question: "How has Donatic improved transparency with your donors?",
    videoId: "eScQ75UM-pU",
    transcript: `Okay, so I think before, we had a campaign on our website where people could donate money toward school supplies. And while, of course, we used that money to purchase supplies, there wasn't a direct connection between donating the money and seeing the actual school supplies being donated.
    
    With Donatic, it's just like an Amazon cart—people pick the exact items, put them in their cart, and donate them. It improves transparency because donors really feel like they're giving the specific item they wanted to contribute.`
  },
  {
    question: "What has been your favorite part of using Donatic for your campaigns?",
    videoId: "X_vnOtfFtgQ",
    transcript: `My favorite part of using Donatic has been the ease with which we can just throw the link into a newsletter or a social media post, and people can donate right away.
    
    It's so user-friendly—people can click the link, load their cart with items, and the donations come right to our door. There's no need to figure out how to get ourselves to Barta to pick up a donation that someone couldn't bring on the plane, or how to get the word out for people to bring an extra suitcase and have that whole conversation.
    
    The user-friendly aspect—just being able to drop a link in our social media, people click it, and the items arrive at our door—reduces our legwork tremendously.`
  },
  {
    question: "If you could describe Donatic in one word or phrase, what would it be?",
    videoId: "uXHEBp_k0dY",
    transcript: `Kind and efficient.`
  },
  {
    question: "How do you see Donatic helping EntreAmigos in the future?",
    videoId: "OUf2NLKd7eM",
    transcript: `I think in the future, having this school supply campaign in the summer—when we don't have as many people donating as often and it's not right in the middle of all the other fundraising campaigns—will be really beneficial.
    
    I feel like we can really promote it and get the school supplies we need over the summer so that in August, when we have our big giveaway, we already have everything we need.`
  },
  {
    question: "What advice would you give to other nonprofits considering Donatic for their campaigns?",
    videoId: "Cj_3qoUzToE",
    transcript: `My advice to other nonprofits considering using Donatic would be to be as specific as possible about the theme of your campaign.
    
    If your campaign is all over the map—like, "Oh, we need school supplies, and we also need food, and then we also need whatever it is from all these different categories"—those things might all be useful. But in terms of marketing the campaign and having a very clear message to donors, it's important for them to see exactly what they're donating to.
    
    Having a very specific campaign and making it time-sensitive is key. For example, if we're doing a school supply campaign, we need to say, "We need this done by July so we can do our school supply giveaway in August." Donors then feel that sense of urgency—"Okay, it's a specific campaign, they need school supplies, and they need it by August. Let me click now."
    
    If it's ongoing, like a general donation request, people are less likely to pull the trigger and click the button if they think they have all the time in the world.
    
    That would be my suggestion.`
  }
];