import React from "react";
import Slider from "react-slick";
import { Card, CardContent, CardMedia, Typography, Button, Box } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { GET_SALES_PAGE } from "../../SalesPages/sales-page.queries";
import { useQuery } from "@apollo/client";
import MaxLengthText from "../../shared/MaxLengthText";
import { Link as RouterLink } from "react-router-dom";

const settings = {
  marginTop: 20,
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  initialSlide: 1,
  autoplay: true,
  autoplaySpeed: 2000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const CampaignSlider: React.FC = () => {
  const { data, loading, error } = useQuery(GET_SALES_PAGE, { variables: { identifier: 'bahia-banderas' } });

  const logos = data?.salesPage?.organizations?.map((org: any) => {
    return {
      img: org.logoUrl,
      title: org.name,
      path: `/o/${org.uuid}`
    }
  });

  const campaigns = data?.salesPage?.organizations?.filter((org: any) => !!org.latestCampaign).map((org: any) => {
    const { latestCampaign } = org;
    return {
      name: latestCampaign.name,
      _id: latestCampaign._id,
      description: latestCampaign.description,
      imageUrl: latestCampaign.imageUrl,
      organization: org
    }
  });

  return (
    <Slider {...settings}>
      {campaigns?.map((campaign: any, index: number) => (
        <Card key={index} sx={{ maxWidth: 300, mx: "auto", p: 1, backgroundColor: 'rgba(250, 250, 250, 1.0);' }}>
          <CardMedia component="img" height="140" image={campaign.imageUrl} alt={campaign.name} />
          <CardContent>
            <Typography variant="h6" gutterBottom>
              {campaign.title}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              <MaxLengthText maxLength={200} text={campaign.description} />
            </Typography>
            <Box sx={{ width: '100%', mt: 2, textAlign: 'center' }}>
              <img style={{ display: 'inline' }} src={campaign.organization.logoUrl} alt={campaign.organization.name} height="50px" />
            </Box>
            <Button variant="contained" color="secondary" sx={{ mt: 2 }} component={RouterLink} to={`/donate/${campaign._id}`}>
              Donate Now
            </Button>
          </CardContent>
        </Card>
      ))}
    </Slider>
  );
};

export default CampaignSlider;
