import { gql } from "@apollo/client";

export const GET_ORGANIZATION = gql`
  query GetOrganization($uuid: ID) {
    organization(uuid: $uuid) {
      _id
      name
      description
      website
      logoUrl
      qrCode
      isVerified
    }
  }
`;

export const GET_ORGANIZATION_PUBLIC = gql`
  query GetOrganizationPublic($uuid: ID) {
    organization(uuid: $uuid) {
      name
      description
      website
      logoUrl
      qrCode
      isVerified
      uuid
      campaigns {
        _id
        name
        imageUrl
        description
        region
        user {
          givenName
        }
        organization {
          name
          uuid
          logoUrl
        }
      }
    }
  }
`;

export const GET_ORGANIZATION_WISHLIST = gql`
  query GetOrganizationWishlist($orgId: ID, $region: String) {
    getOrgWishlist(orgId: $orgId, region: $region) {
      _id
      uuid
      name
      items {
        item {
          name
          description
          imageUrl
        }
      }
    }
  }
`;

export const SAVE_ORGANIZATION = gql`
  mutation SaveOrganization($organization: OrganizationInput, $organizationId: ID) {
    saveOrganization(organization: $organization, organizationId: $organizationId) {
      _id
      uuid
      name
      description
      website
      email
      logoUrl
      qrCode
    }
  }
`;

export const LIST_ORGANIZATIONS = gql`
  query ListOrganizations($page: Int) {
    organizationsPublic(page: $page) {
      page
      hasMore
      organizations {
        uuid
        name
        description
        website
        email
        logoUrl
        qrCode
      }
    }
  }
`;