import { Grid, Link, List, ListItem, Paper, Typography } from "@mui/material";
import { GET_ORGANIZATION_PUBLIC } from "../../Organizations/organization.queries";
import LoadingError from "../../Layout/LoadingError";
import Loading from "../../Layout/Loading";

import logoSquarePink from "../../logo-d-white-on-pink-square-100x100.png";
import { useQuery } from "@apollo/client";
import CaseStudyQuestionComponent, { CaseStudyQuestionComponentProps } from "../CaseStudyQuestionComponent";
import { Link as RouterLink } from "react-router-dom";
import { entreAmgiosLearnings, entreAmgiosQuestionData, entreAmigosTakeaways } from "./entre-amigos-case-study-data";
import { displayNewLineStyles } from "../../shared/displayNewLine.styles";

/**
 *
 */
export default function EntreAmigosCaseStudy() {
  const organizationUuid = '94e82866-f922-4495-bda8-bf41e8b172f6';

  const { loading, error, data } = useQuery(GET_ORGANIZATION_PUBLIC, { variables: { uuid: organizationUuid } });

  if (loading) return <Loading />;
  if (error || !organizationUuid) return <LoadingError />;

  const organization = data.organization;

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Typography color="primary" variant="h4" component="h1">How Donatic is Helping EntreAmigos Expand Its Impact</Typography>
      </Grid>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2, paddingBottom: 1, mt: 1 }}>
            <Typography paragraph>
              <b>Joined</b> Donatic: <b>May 2024</b><br />
              <b>2 Campaigns</b> Launched<br />
              <b>13 Donations Received</b> via Donatic<br />
              <b>85 Items Donated</b> via Donatic
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8} textAlign={{ xs: 'center', md: 'right' } }>
          <img src={organization?.logoUrl ?? logoSquarePink} height="60" /><br />
          <Link color="secondary" href={organization?.website} target="_blank">{organization?.website}</Link>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph>
         For nearly 20 years, EntreAmigos has been a cornerstone of the San Pancho community, fostering education, environmental care, and sustainable development. Like many nonprofits, EntreAmigos relies on the generosity of donors to support its programs, but traditional fundraising methods often presented challenges—until they started using Donatic. Here's how Donatic has transformed their fundraising efforts, making it easier, more transparent, and more impactful.
        </Typography>

        <Typography paragraph>
          tldr; Key Learnings:
        </Typography>

        <Paper sx={{ padding: 5, pt: 0, pb: 0, mb: 2 }}>
          <List sx={{ listStyleType: 'disc' }}>
            {
              entreAmgiosLearnings.map((learning: string, index: number) => {
                return <ListItem key={index} sx={{ display: 'list-item' }}>{learning}</ListItem>
              })
            }
          </List>
        </Paper>
      </Grid>

      <Grid item xs={12}>
      {
        entreAmigosTakeaways.map((takeaway: { title: string, content: string }, index: number) => {
          return (
            <Grid key={index} container sx={{ mt: 1, mb: 1 }}>
              <Grid item xs={12} sx={{ mb: 2 }}>
                <Typography color="primary" variant="h5" component="h2">
                { takeaway.title }
              </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography paragraph sx={displayNewLineStyles}>
                  { takeaway.content }
                </Typography>
              </Grid>
            </Grid>
          )
        })
      }
      </Grid>

      <Grid item xs={12}>
        <Typography color="primary" variant="h5" component="h2" sx={{ mb: 2 }}>
          Looking Ahead
        </Typography>
        <Typography paragraph>
          With Donatic, EntreAmigos has increased efficiency and transparency in its fundraising efforts. As they continue to refine their campaigns, they see even greater potential to build a stronger, more engaged community of supporters.
        </Typography>
        <Typography paragraph>
          For nonprofits considering Donatic, the advice is clear: Keep your campaigns specific, time-sensitive, and donor-friendly. By doing so, you can maximize impact while making giving easier and more rewarding for everyone involved.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography paragraph>
          Read on below for the full interview and to watch the videos to hear from Sarah and Aura directly!
        </Typography>
       
      </Grid>

      {
        entreAmgiosQuestionData.map((questionData: unknown, index: number) => {
          const data = questionData as CaseStudyQuestionComponentProps;
          console.log(data);
          return <CaseStudyQuestionComponent key={index} {...data} />
        })
      }

      <Grid item xs={12}>
        <Typography paragraph>
          Check out their Donatic page to see what they currently are campaigning for! <Link color="secondary" component={RouterLink} to={`/o/${organization?.uuid}`}>Donate now.</Link>
        </Typography>
      </Grid>
    </Grid>
);
}
