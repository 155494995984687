import { CaseStudyQuestionComponentProps } from "../CaseStudyQuestionComponent";

export const sayulitaAnimalsLearnings: string[] = [
  `Donatic Helps Address SayulitAnimals' Core Mission`,
  'Increased Transparency for Donors',
  'Simplified Donation Process',
  'Adaptability to Changing Needs',
  'Easy, User-Friendly Experience',
  'Strong Recommendation for Other Nonprofits'
];

export const sayulitaAnimalsTakeaways: { title: string, content: string }[] = [
  {
    title: "Donatic Helps Address SayulitAnimals’ Core Mission",
    content: `SayulitAnimals was founded in response to the overpopulation of street dogs and cats in Sayulita.

Their main programs include spay and neuter, adoption assistance, medical aid for street animals, and community education.

The nonprofit relies on donor support to sustain these programs, as they do not generate revenue through sales.`
  },
  {
    title: "Increased Transparency for Donors",
    content: `Many donors prefer not to give money directly because they want control over how their donation is used.

With Donatic, donors can purchase specific materials—like sterile gloves, sutures, leashes, collars, and cat traps—ensuring that their contributions directly support the spay and neuter program.`
  },
  {
    title: "Simplified Donation Process",
    content: `Donatic removes logistical hurdles by delivering items directly to the nonprofit, saving them time and effort.

Instead of having to purchase supplies themselves, SayulitAnimals receives exactly what they need, allowing them to focus on their work.`
  },
  {
    title: "Adaptability to Changing Needs",
    content: `SayulitAnimals' needs change over time, and Donatic allows them to update their wishlist easily.

This flexibility ensures they receive timely support for their most pressing needs.`
  },
  {
    title: "Easy, User-Friendly Experience",
    content: `Donors find Donatic intuitive—it's described as "like going shopping," making it effortless to contribute.

The ease of use encourages more participation and support from people who want to help but might not otherwise know how.`
  },
  {
    title: "Strong Recommendation for Other Nonprofits",
    content: `SayulitAnimals highly recommends Donatic, emphasizing that nonprofits have nothing to lose and everything to gain by using it.`
  }
]

export const sayulitaAnimalsQuestionData: CaseStudyQuestionComponentProps[] = [
  {
    question: "What inspired you to start SayulitAnimals?",
    videoId: "aDrZi4628HQ",
    transcript: `I decided to live in Sayulita 20 years ago, and I saw what was going on—street dogs everywhere, mangy dogs, puppies on the beach without a home. And I just thought, I want to live here, but I can't live here and just turn my head. I need to do something actively to help those animals.`
  },
  {
    question: "Can you tell us about SayulitAnimals and its mission?",
    videoId: "gHiZw-OXO2U",
    transcript: `SayulitAnimals has been in action since 2009, and it was formed as a response to the overpopulation of animals—street dogs and cats—in Sayulita. It was built on four programs: spay and neuter, adoption assistance, medical aid for street animals, and community education.`
  },
  
  {
    question: "What are the biggest challenges your organization faces?",
    videoId: "AhBJz9n5pCc",
    transcript: `Well, since SayulitAnimals is a nonprofit organization, we don't sell anything.

We are really dependent on donors who believe in what we do, and that is always the biggest challenge for us—funding and maintaining our programs economically, if that makes sense.`
  },
  
  {
    question: "How has Donatic helped SayulitAnimals achieve its mission?",
    videoId: "XdWZDKuNS3U",
    transcript: `So our main program is spay and neuter, and through Donatic, people who want to support that program—but don't just want to give money because they don't have control over where it actually goes—can purchase the materials we need directly.

They can go on Donatic and buy things like sterile gloves, sutures, collars, leashes, and cat traps—things that are essential for our spay and neuter program.`
  },
  
  {
    question: "What has been your favourite part of using Donatic for your campaigns?",
    videoId: "EmgaI4ihcVg",
    transcript: `What’s really amazing about Donatic is that before, I would have to go out and purchase those sterile gloves, leashes, or collars myself. But now, through Donatic, they’re brought directly to me, which frees up my time to focus on other important things.`
  },
  
  {
    question: "If you could describe Donatic in one word or phrase, what would it be?",
    videoId: "SmHKY90orWE",
    transcript: `Donatic is a very user-friendly donation platform—it's so easy! You just click, and it’s like shopping. But instead of items being delivered to you, they go straight to the organization you're supporting. It's phenomenal!`
  },
  
  {
    question: "How do you see Donatic helping your nonprofit in the future?",
    videoId: "6AVz30PqfEg",
    transcript: `Our needs list can change—and it does change—and Donatic always adjusts. Today, we might need certain materials, but tomorrow, we might need something different. So again, it’s very, very helpful. They’re doing a really great job supporting nonprofits in this way.`
  },
  
  {
    question: "What advice would you give to other nonprofits considering Donatic for campaigns?",
    videoId: "lhRXLM4BqGE",
    transcript: `I would totally recommend it—just do it! Get on it, sign up for it. You can’t lose—you can only win.`
  },
];