import { Grid, Link, List, ListItem, Paper, Typography } from "@mui/material";
import { GET_ORGANIZATION_PUBLIC } from "../../Organizations/organization.queries";
import LoadingError from "../../Layout/LoadingError";
import Loading from "../../Layout/Loading";

import logoSquarePink from "../../logo-d-white-on-pink-square-100x100.png";
import { useQuery } from "@apollo/client";
import CaseStudyQuestionComponent, { CaseStudyQuestionComponentProps } from "../CaseStudyQuestionComponent";
import { catsOnCornerLearnings, catsOnCornerQuestionData } from "./cats-on-corner-case-study-data";
import { Link as RouterLink } from "react-router-dom";

/**
 *
 */
export default function CatsOnCornerCaseStudy() {
  const organizationId = '20949725-a581-4e41-b64f-f48b483db4dd';

  const { loading, error, data } = useQuery(GET_ORGANIZATION_PUBLIC, { variables: { uuid: organizationId } });

  if (loading) return <Loading />;
  if (error || !organizationId) return <LoadingError />;

  const organization = data.organization;

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <Typography color="primary" variant="h4" component="h1">A Case Study: Cats On The Corner</Typography>
      </Grid>
      <Grid container sx={{ mb: 2 }}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ padding: 2, paddingBottom: 1, mt: 1 }}>
            <Typography paragraph>
              <b>Joined</b> Donatic: <b>May 2024</b><br />
              <b>2 Campaigns</b> Launched<br />
              <b>16 Donations Received</b> via Donatic<br />
              <b>42 Items Donated</b> via Donatic
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} md={8} textAlign={{ xs: 'center', md: 'right' } }>
          <img src={organization?.logoUrl ?? logoSquarePink} height="60" /><br />
          <Link color="secondary" href={organization?.website} target="_blank">{organization?.website}</Link>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph>
          We recently sat down with Cats On The Corner organizer, Stine, to discuss their experience with Donatic.
        </Typography>
        <Typography paragraph>
          Our main learnings from our chat with Stine were:
        </Typography>

        <Paper sx={{ padding: 5, pt: 0, pb: 0, mb: 2 }}>
          <List sx={{ listStyleType: 'disc' }}>
            {
              catsOnCornerLearnings.map((learning: string, index: number) => {
                return <ListItem key={index} sx={{ display: 'list-item' }}>{learning}</ListItem>
              })
            }
          </List>
        </Paper>
        
        <Typography paragraph>
          Read below for the full interview, and watch the videos to hear from Stine herself.
        </Typography>
       
      </Grid>

      {
        catsOnCornerQuestionData.map((questionData: unknown, index: number) => {
          const data = questionData as CaseStudyQuestionComponentProps;
          console.log(data);
          return <CaseStudyQuestionComponent key={index} {...data} />
        })
      }

      <Grid item xs={12}>
        <Typography paragraph>
          We heard you, Stine! After learning more about how we can improve, <b>we are now working with a local vet to provide services to Cats On The Corner</b>.
        </Typography>
        <Typography paragraph>
          You can now donate sterilizations and vaccinations directly to Cats On The Corner. <Link color="secondary" component={RouterLink} to={`/o/${organization?.uuid}`}>Donate now.</Link>
        </Typography>
        </Grid>
    </Grid>
);
}
