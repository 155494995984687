import { Box, Link, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";

/**
 *
 * CaseStudy Home -> Preview/link to current case studies.
 */
export default function CaseStudyHome() {
  return (
    <Box>
      <Typography>
        <Link component={RouterLink} color="secondary" to="/cases/cats-on-the-corner">
          Cats On The Corner
        </Link>
      </Typography>
      <Typography>
        <Link component={RouterLink} color="secondary" to="/cases/entre-amigos">
          EntreAmigos
        </Link>
      </Typography>
      <Typography>
        <Link component={RouterLink} color="secondary" to="/cases/sayulita-animals">
          SayulitAnimals
        </Link>
      </Typography>
    </Box>
  );
}
