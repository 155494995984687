import { Container, Grid, Typography } from "@mui/material";
import { Outlet } from "react-router-dom";

/**
 *
 * CaseStudy Layout.
 */
export default function CaseStudyLayout() {
  return (
    <Container>
      <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ pt: 1, margin: { md: '0 auto !important', lg: '0 auto !important' } }} maxWidth="md">
        <Grid item xs={12} sx={{ mt: 1 }}>
          <Typography color="secondary" variant="overline">Donatic Case Studies</Typography>
        </Grid>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  );
}
