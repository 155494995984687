import { Grid, Typography } from "@mui/material";
import { displayNewLineStyles } from "../shared/displayNewLine.styles";
import { borderRadius } from "@mui/system";

export interface CaseStudyQuestionComponentProps {
  question: string;
  videoId: string;
  transcript: string;
}

const iframeStyles = {
  width: '237px',
  height: '420px',
  borderRadius: '8px',
  border: 0,
}

/**
 *
 * The User Dashboard Page.
 */
export default function CaseStudyQuestionComponent({ question, videoId, transcript }: CaseStudyQuestionComponentProps) {
  const videoSource = `https://www.youtube.com/embed/${videoId}?autoplay=0&hl=en&cc_lang_pref=en&cc_load_policy=1`;

  return (
  <Grid container sx={{ mt: 1, mb: 1 }}>
    <Grid item xs={12} sx={{ mb: 2 }}>
      <Typography color="secondary" variant="h5" component="h2" textAlign="left">
        { question }
      </Typography>
    </Grid>
    <Grid item xs={12} sm={6} md={4}>
      <iframe style={iframeStyles} src={videoSource} allowFullScreen ></iframe>
    </Grid>
    <Grid item xs={12} sm={6} md={8}>
      <Typography paragraph sx={displayNewLineStyles}>
        { transcript }
      </Typography>
    </Grid>
  </Grid>
);
}
