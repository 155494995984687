import { CaseStudyQuestionComponentProps } from "../CaseStudyQuestionComponent";

export const catsOnCornerLearnings: string[] = [
  'Using Donatic forced the organization to think about their needs in a more structured way.',
  'Fundraising for supplies simplified the process of getting the right items to the organization.',
  'Receiving tangible donations this way helped the organization feel more secure in their ability to feed the cats during the summer months.',
  'Cats On The Corner would like to expand their use of Donatic to include vet services in the future.',
];

export const catsOnCornerQuestionData: CaseStudyQuestionComponentProps[] = [
  {
    question: "Can you tell us about Cats On The Corner and its mission?",
    videoId: "EewB-Vmj0HI",
    transcript: `My name is Stina Haynes, and I'm with Cats on the Corner. So, we are pretty much what the name suggests—we're a bunch of cats living on a corner in Sayulita, Mexico. And I guess you could say I'm the founder, if anybody really is the founder of something like that. But I was the first person to start regularly feeding and sterilizing these cats.
    
    This happened during the pandemic because, as you might imagine, cats congregate around where they can eat, which was garbage piles. And one particular garbage pile disappeared completely because there were no people around to leave garbage for the cats. So, what I was walking past was 35 starving cats at that time.`
  },
  {
    question: "What inspired you to start with Cats On The Corner?",
    videoId: "smzQuFVOF1U",
    transcript: `I guess you could say what inspired me to to start Cats on the Corner was I was unable to walk past starving animals.

    I knew in the back of my head at least I knew that starting to feed 35 cats without much of a plan is a is a bad plan, you could say, and at the same time it was a hands-on task.

    Something that I could do and I definitely could not just walk past it now pretty soon these cats uh were waiting for me and I could not not bring them food so you I guess you can say once you say A, you say B you say C and one of the things that happened pretty pretty soon down the line of those letters is that you need to sterilize them and that was rather hard because how do you capture these cats?

    How do you where do you take them how do you pay for it?
    I was just one person at this at this time.`
  },
  {
    question: "What are the biggest challenges your organization faces in achieving its goals?",
    videoId: "roi-JKM5h6Y",
    transcript: `Today, we are three people actively involved with Cats on the Corner. We have a morning feeding and an evening feeding, and the evening feeders take turns. I'm the only morning feeding person, so we've got a routine going.
    
    I guess you could say the biggest challenges we're facing today—after having sterilized everyone and found homes for half these cats—are that we're now down to 15 full-time residents, and the biggest challenge we have is feeding them. They eat a lot. Healthy, happy cats eat a lot.
    
    On top of that, some of them will get sick. Luckily, it's usually one cat at a time, so we get to help them. But we often have a little bit of a discussion—how much to help, how much to spend, how to spend it. And those are the challenges we have to face every single day on the corner.`
  },
  {
    question: "How has Donatic helped Cats On The Corner achieve its mission?",
    videoId: "wXLGzSoVkaU",
    transcript: `So, when Donatic reached out to us, I immediately saw the potential of what it could do for Cats on the Corner. In fact, I kind of just looked at it for a moment and said, "Hey, wait—this is possible! We could make a campaign, say what our needs are, and people can click their way to support us."
    
    To me, it sounded kind of simple, yet exactly what we needed at that point. Because, first of all, we needed to figure out exactly what we needed for the cats. It was such a hands-on task—like, how much do these cats eat per month? I didn't know. We didn't know. We'd just been running around in circles trying to feed everybody.
    
    And all of a sudden, we got to sit down and make a plan. This is how much dry food kibbles we go through in a month. This is how much wet food. What else could we need? Oh, wait—plates! Wouldn't it be nice not to feed the cats on the ground?`
  },
  {
    question: "Can you share a specific success story or example of how Donatic has made a difference in your fundraising efforts?",
    videoId: "-XopJj8nekM",
    transcript: `In the past, people have donated to Cats on the Corner very randomly—like cash donations happening in the street where the cats are, banknotes dropped into my hand, or, on occasion, a can of tuna or a small bag of food. Very random donations from random people.

    So, when we were putting together the summer campaign with Donatic, we didn't really know if anyone was going to donate like that. And I guess I was a little bit nervous about how people were going to respond to such a targeted effort.
    
    Then we got our first donation—somebody had clicked on every single item that we needed! It felt so wild. At some stage, I thought it was a mistake, like a test of the app. I even asked my helper, "Well, it says '1' in all the little boxes—does that mean we get one, or is it just a default?" And she was like, "No, I just got the confirmation email—we're getting all these items."
    
    And I was like, "How? Where to?"
    
    She said, "To my home—it's going to arrive right here."
    
    I mean, I didn't believe it until I rolled up and saw all that cat food in one pile.`
  },
  {
    question: "How has Donatic improved transparency or trust with your donors?",
    videoId: "g9_zMo6Juh8",
    transcript: `So, it's my feeling that people want to help—they just don't always know how. But I think, by nature, if we make it easy for people to help, they're more willing and able to do it, especially if they know exactly what they are helping with.
    
    With Donatic, you can see exactly what you're buying, that we receive it, and that the cats eat it—most of the time.
    
    There was this one time a woman got very excited about giving us a carrier. We had put a carrier in the campaign because we needed to bring a cat to the vet, and we didn't have one. She saw that as a very basic need—and she was right.
    
    She bought the carrier, saw it arrive, saw it being brought to the vet, and the cat got sterilized. It doesn't get much more hands-on than that.`
  },
  {
    question: "What has been your favorite part of using Donatic for your campaigns?",
    videoId: "0wE0qEZlvsA",
    transcript: `For those of you who know Sayulita, you know that summers are a beast. They're very difficult to deal with—we get storms, we get humidity, and the heat is intense.
    
    Of course, all the random donations disappear because people leave. And it feels kind of scary having the responsibility for all these cats when there's no one else around who can help.
    
    We made the summer campaign with Donatic, and these clicks started to materialize. These little clicks online became actual food that arrived at my helper's place. And suddenly, we had enough food to see us through three months of insane summer weather.
    
    The sense of safety I felt as the main person responsible for feeding them—for the first time ever since I started Cats on the Corner—I knew that I was able to feed everyone for the next three months.
    
    That's huge. That is the biggest luxury I can imagine.`
  },
  {
    question: "If you could describe Donatic in one word or phrase, what would it be?",
    videoId: "PZVb3oTLqCs",
    transcript: `Okay, let's see, if you could describe Donatic in one word of phrase - oh - hands on help.`
  },
  {
    question: "What advice would you give to nonprofits considering using Donatic for their campaigns?",
    videoId: "https://www.youtube.com/watch?v=bEJgPx3_9Kw&list=PLtVHPwTPafcrIEUeKVb6wrnipSDdmU8PW&index=9",
    transcript: `So after having worked with Donatic for about a year now, I can honestly say that I recommend Donatic for everything for the nonprofits, charities and donors as well.
    
    I don't really see an easier way to donate and know what you're donating and know what your money goes to and as an organization it has helped with clarity, feeling safe in my efforts I actually become more targeted about what we're doing in the future.
    
    I would love for Donatic to to be able to help with other things like, maybe some vet products I guess I'm not sure how that would work but, but that would be my hope.`
  }
];